<section id="news" class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>.: My Skills :.</h2>
            <p>I'm a self-motivated, well-disciplined, individual poised with excellent
                leadership and management skills. Able to work independently and
                collaboratively with high inquisitiveness to explore and learn new things. I'm also Keen to learn,
                explore and apply new knowledge.
                and enjoy participating in social activities and organizations.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-content">
                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>HTML</h3></td>
                                    <td style="text-align: right;">90% [Expert]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 90%">
                                </div>
                            </div>
                        </div>

                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>Angular 2+</h3></td>
                                    <td style="text-align: right;">70% [Proficient]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 70%">
                                </div>
                            </div>
                        </div>

                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>CSS/LESS/SCSS</h3></td>
                                    <td style="text-align: right;">60% [Proficient]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 60%">
                                </div>
                            </div>
                        </div>

                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>Git</h3></td>
                                    <td style="text-align: right;">45% [Novice]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 45%">
                                </div>
                            </div>
                        </div>

                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>Docker</h3></td>
                                    <td style="text-align: right;">20% [Novice]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 20%">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-content">
                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>JAVA</h3></td>
                                    <td style="text-align: right;">85% [Expert]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 85%">
                                </div>
                            </div>
                        </div>

                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>NodeJS</h3></td>
                                    <td style="text-align: right;">70% [Proficient]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 70%">
                                </div>
                            </div>
                        </div>

                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>Javascript</h3></td>
                                    <td style="text-align: right;">80% [Proficient]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 80%">
                                </div>
                            </div>
                        </div>

                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>Typescript</h3></td>
                                    <td style="text-align: right;">80% [Proficient]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 80%">
                                </div>
                            </div>
                        </div>

                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>Flutter</h3></td>
                                    <td style="text-align: right;">45% [Novice]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 45%">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-content">
                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>Oracle</h3></td>
                                    <td style="text-align: right;">55% [Proficient]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 55%">
                                </div>
                            </div>
                        </div>

                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>MySQL</h3></td>
                                    <td style="text-align: right;">70% [Proficient]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 70%">
                                </div>
                            </div>
                        </div>

                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>MongoDB</h3></td>
                                    <td style="text-align: right;">40% [Novice]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 40%">
                                </div>
                            </div>
                        </div>

                        <div class="section-title1">
                            <table style="width: 100%;">
                                <tr>
                                    <td><h3>Pentaho Report Designer</h3></td>
                                    <td style="text-align: right;">80% [Proficient]</td>
                                    </tr>
                            </table>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                    role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 80%">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
