import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeComponent } from './components/pages/home/home.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/layouts/blog/blog.component';
import { SubscribeComponent } from './components/layouts/subscribe/subscribe.component';
import { GalleryComponent } from './components/layouts/gallery/gallery.component';
import { PartnersComponent } from './components/layouts/partners/partners.component';
import { ProductsComponent } from './components/layouts/products/products.component';
import { MatchesHighlightsComponent } from './components/layouts/matches-highlights/matches-highlights.component';
import { UpcomingMatchesComponent } from './components/layouts/upcoming-matches/upcoming-matches.component';
import { NextMatchComponent } from './components/layouts/next-match/next-match.component';
import { MainBannerComponent } from './components/layouts/main-banner/main-banner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    BlogDetailsComponent,
    BlogComponent,
    SubscribeComponent,
    GalleryComponent,
    PartnersComponent,
    ProductsComponent,
    MatchesHighlightsComponent,
    UpcomingMatchesComponent,
    NextMatchComponent,
    MainBannerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
