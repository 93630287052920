<div id="home" class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="main-banner-content">
                    <span class="sub-title">Hello, I'm</span>
                    <h1>haqqiem</h1>
                    <span class="sub-title">I'm a
                        <span #textElement></span>
                        <span #blinkElement></span>
                    </span>
                    <span class="sub-title">Based in Kuala Lumpur, Malaysia</span>
                    <div class="section-title2">
                        <h3 class="about-image"><a href="#" target="_blank">Download CV
                            <span class="shape">
                                <h6><i class="fa fa-arrow-down" aria-hidden="true"
                                            title="Here"></i></h6>
                            </span></a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-footer-content">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <a href="mailto:haqqiem@gmail.com" class="email-link">haqqiem@gmail.com</a>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul class="social">
                        <li><a href="https://www.facebook.com/haqqiem.gafa" target="_blank"><i class="flaticon-facebook"
                                    title="Facebook"></i></a></li>
                        <li><a href="https://twitter.com/haqqiem86" target="_blank"><i class="flaticon-twitter-1"
                                    title="Twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/abdul-hakim-a-gafa-520a6a161/" target="_blank"><i
                                    class="flaticon-linkedin-1" title="LinkedIn"></i></a></li>
                        <li><a href="https://github.com/haqqiem" target="_blank"><i class="fa fa-github"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
