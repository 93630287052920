<footer class="footer-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <p><i class="flaticon-copyright"></i>All Rights Reserved, 2020</p>
            </div>

            <div class="col-lg-4 col-md-12">
                <ul class="social">
                    <li><a href="https://www.facebook.com/haqqiem.gafa" target="_blank"><i class="flaticon-facebook-logo"></i></a></li>
                    <li><a href="https://twitter.com/haqqiem86" target="_blank"><i class="flaticon-twitter"></i></a></li>
                    <!-- <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li> -->
                    <li><a href="https://www.linkedin.com/in/abdul-hakim-a-gafa-520a6a161/" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                    <li><a href="https://github.com/haqqiem" target="_blank"><i class="fa fa-github"></i></a></li>
                </ul>
            </div>

            <!-- <div class="col-lg-4 col-md-12">
                <ul class="info-link">
                    <li><a routerLink="#">Privacy Policy</a></li>
                    <li><a routerLink="#">Terms & Conditions</a></li>
                </ul>
            </div> -->
        </div>
    </div>
</footer>
