<app-main-banner></app-main-banner>

<!-- <app-next-match></app-next-match> -->

<app-upcoming-matches></app-upcoming-matches>

<!-- <app-matches-highlights></app-matches-highlights>

<app-products></app-products>

<app-partners></app-partners>

<app-gallery></app-gallery>

<app-subscribe></app-subscribe> -->

<app-blog></app-blog>
