<section id="matches" class="upcoming-matches-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>.: Working Experience :.</h2>
            <p>Experienced Software Developer adept in bringing forth expertise in design, installation, testing, and
                maintenance of software systems. Equipped with a diverse and promising skill-set. Proficient in various
                platforms, languages, and embedded systems. Experienced with the latest cutting edge development tools
                and procedures. Able to effectively self-manage during independent projects, as well as collaborate as
                part of a productive team. </p>
        </div>

        <div class="upcoming-matches-slides owl-carousel owl-theme">
            <div class="single-upcoming-matches-item">
                <div class="date">
                    <span>Etiqa Life Insurance Berhad
                        <br>
                        <small>Kuala Lumpur | Mar 2020 - Present</small>
                    </span>
                </div>
                <h3>
                    Fullstack Developer
                    <img src="assets/img/etiqa.png" class="company-logo" style="width: 150px;margin-left: 90px;">
                </h3>
                <div class="vs-matches">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                    leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </div>
                <span class="default-btn">-1-</span>
            </div>

            <div class="single-upcoming-matches-item">
                <div class="date">
                    <span>Rapidevelop Technology Sdn. Bhd
                        <br>
                        <small>Cyberjaya | Oct 2017 - Feb 2020</small>
                    </span>
                </div>
                <h3>Senior Developer
                    <img src="assets/img/rapid.png" class="company-logo" style="width: 250px;margin-left: 40px;">
                </h3>
                <div class="vs-matches">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                    leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </div>
                <span class="default-btn">-2-</span>
            </div>

            <div class="single-upcoming-matches-item">
                <div class="date">
                    <span>Theta Edge Berhad
                        <br>
                        <small>Selangor | May 2009 - Sep 2017</small>
                    </span>
                </div>
                <h3>
                    Senior Developer
                    <img src="assets/img/theta.png" class="company-logo" style="width: 280px;margin-left: 40px;">
                </h3>

                <div class="vs-matches">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                    leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </div>
                <div><br></div>
                <span class="default-btn">-3-</span>
            </div>

            <div class="single-upcoming-matches-item">
                <div class="date">
                    <span>Open Architecture Solutions Sdn. Bhd
                        <br>
                        <small>Putrajaya | Dec 2008 - May 2009</small>
                    </span>
                </div>
                <h3>Developer<br>
                    <img src="assets/img/oas.png" style="width: 250px;margin-left: 40px; height: 40px;">
                </h3>
                <div class="vs-matches">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                    leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </div>
                <div>&nbsp;</div>
                <span class="default-btn">-4-</span>
            </div>
        </div>
    </div>


    <div class="upcoming-matches-shape1">
        <div class="col-lg-6 col-md-12 img-developer">
            <div class="about-image">
                <img src="assets/img/developer.png" alt="image">
                <p style="font-size: xx-small;text-align: center;">
                    <a href='https://www.freepik.com/vectors/business' title="Business vector created by freepik -
                www.freepik.com">Business vector created by freepik -
                        www.freepik.com</a>
                </p>
                <div class="shape">
                    <img src="assets/img/about-shape1.png" alt="image">
                    <img src="assets/img/about-shape2.png" alt="image">
                </div>
            </div>

        </div>
    </div>
    <!-- <div class="upcoming-matches-shape1"><img src="assets/img/exp.png" width="700" alt="image"></div> -->
</section>
