<section id="partners" class="partners-area bg-161616 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Brand Partners</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img1.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img2.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img3.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img4.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img5.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img6.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img7.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img8.png" alt="image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>